<template>
    <div class="d-flex flex-column flex-root"></div>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
    name: 'AdminPanel',
    computed:{
        ...mapGetters(['getCompanyInfo'])
    },
    methods: {
        redirect() {
            window.location.href = "http://"+ this.getCompanyInfo.admin_domain;
        }
    },
    created() {
        this.redirect();
    }
}
</script>
